<!-- SlowSickManageCore 慢病管理中心 --> 
<template>
    <div>
        <isHeader />
        <isBanner :title="title" :desc="desc" :src="src"/>
        <isPro1 v-runshow/>
        <isPro2 v-runshow/>
        <isPro3 v-runshow/>
        <isFooter />
    </div>
</template>

<script>
    import isHeader from '@/components/header/index.vue';
    import isBanner from '@/components/Solution/isBanner.vue';
    import isPro1 from '@/components/SlowSickCore/isPro1.vue';
    import isPro2 from '@/components/SlowSickCore/isPro2.vue';
    import isPro3 from '@/components/SlowSickCore/isPro3.vue';
    import isFooter from '@/components/footer/index.vue';
    import imgForBanner from '@/assets/Solution/slowsick_banner.jpg';
    export default {
        name: 'SlowSickManageCore',
        data: () => {
            return {
                title: '慢病管理中心',
                desc: '聚合慢病管理产品矩阵，协助政府和医院推动慢病大数据中心的数据资源化和资产化进程',
                src: imgForBanner,               
            }
        },
        components: {
            isHeader,
            isBanner,
            isPro1,
            isPro2,
            isPro3,
            isFooter,
        }
    }
</script>