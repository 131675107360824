<template>
  <div class="isPro2">
    <div class="centerBox">
      <div class="title">
        <p>{{ title }}</p>
        <p>
          {{ desc }}
        </p>
        <p></p>
      </div>

      <!-- pc -->
      <ul class="pc">
        <li class="isPart_1">
          <div>{{ solution.title }}</div>
          <div>{{ solution.desc }}</div>
          <ul>
            <li v-for="item in solution.list" :key="item.key">
              <img :src="require('@/assets/Solution/isTips.png')" />
              {{ item.text }}
            </li>
          </ul>
        </li>
        <li class="isPart_1">
          <div>{{ client.title }}</div>
          <div>{{ client.desc }}</div>
        </li>
      </ul>

      <!-- mobile -->
      <div class="mobile">
        <div class="isswiper_pro2_2">
          <div class="swiper-wrapper">
            <div class="swiper-slide swip_1">
              <div>{{ solution.title }}</div>
              <div>{{ solution.desc }}</div>
              <ul>
                <li v-for="item in solution.list" :key="item.key">
                  <img :src="require('@/assets/Solution/isTips.png')" />
                  {{ item.text }}
                </li>
              </ul>
            </div>
            <div class="swiper-slide swip_2">
              <div>{{ client.title }}</div>
              <div>{{ client.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "我们的解决方案",
      desc: "以数字化为切入点  助力医防融合的慢病防治体系",

      solution: {
        title: "解决方案",
        desc: "整合慢病管理管理平台、随访管理管理平台、健康宣教管理平台、智能监测设备和慢病精细化管理服务构成的产品矩阵，提供慢病管理一体化解决方案。",
        list: [
          {
            key: 1,
            text: "高效率管理",
          },
          {
            key: 2,
            text: "精细化分级、分标管理",
          },
          {
            key: 3,
            text: "智能体测设备",
          },
          {
            key: 4,
            text: "个性化宣教方案",
          },
        ],
      },

      client: {
        title: "客户价值",
        desc: "有效增强慢病患者与医疗资源的联结，满足患者个性化、精细化的医疗服务需求，推动医院智慧化服务延伸，提升医院管理效率，形成“大治疗在医院、小诊疗在社区、康复在居家”的一体化闭环体系。",
      },
    };
  },
  mounted() {
    var mySwiper = new Swiper(".isswiper_pro2_2", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
    });
  },
};
</script>

<style scoped>
.isPro2 {
  width: 100%;
}
.centerBox {
  width: 100%;
  margin: 0 auto;
}

.title {
  height: 3rem;
  background: #373948;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
}

.title p:nth-child(1) {
  font-weight: 900;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.title p:nth-child(2) {
  font-size: 0.38rem;
  padding: 0.16rem 0 0.25rem 0;
}

.title p:nth-child(3) {
  width: 0.74rem;
  height: 0.03rem;
  background: #1aa858;
}

.mobile {
  display: none;
}

.pc {
  font-size: 0;
}

.pc .isPart_1 {
  width: 50%;
  display: inline-block;
  min-height: 5.8rem;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  padding-left: 7%;
}

.pc .isPart_1:nth-child(1) {
  background: url("../../assets/Solution/isPro2_2_1.jpg") no-repeat;
  background-size: cover;
}

.pc .isPart_1:nth-child(2) {
  background: url("../../assets/Solution/isPro2_2_2.jpg") no-repeat;
  background-size: cover;
}

.pc .isPart_1 div:nth-child(1) {
  font-style: normal;
  font-weight: 900;
  font-size: 0.38rem;
  line-height: 0.53rem;
  margin-top: 12.5%;
}
.pc .isPart_1 div:nth-child(2) {
  font-style: normal;
  font-weight: normal;
  font-size: 0.22rem;
  line-height: 0.36rem;
  width: 70%;
  margin-top: 0.24rem;
}

.pc .isPart_1 ul {
  margin-top: 0.48rem;
}

.pc .isPart_1 ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 0.24rem;
  line-height: 0.36rem;
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .title {
    height: auto;
    padding: 0.6rem 0;
  }
  .title p:nth-child(1) {
    line-height: 0.67rem;
    font-size: 0.48rem;
  }
  .title p:nth-child(2) {
    max-width: 90%;
    line-height: 0.67rem;
    font-size: 0.38rem;
    text-align: center;
  }

  .mobile {
    display: block;
    overflow: hidden;
  }

  .pc {
    display: none;
  }

  .mobile .swiper-slide {
    height: 6.7rem;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: normal;
    color: #ffffff;

    /* width: 100%; */
  }

  .title p:nth-child(3) {
    width: 1.3rem;
    height: 0.03rem;
    background: #1aa858;
  }

  .swip_1 {
    background: url("../../assets/Solution/isPro2_2_1.jpg") no-repeat;
    background-size: cover;
  }

  .swip_1 div:nth-child(1) {
    font-weight: 900;
    font-size: 0.38rem;
    line-height: 0.53rem;
    /* padding-top: 10%; */
    color: #ffffff;
    padding: 10% 8% 0;
  }

  .swip_1 div:nth-child(2) {
    font-weight: 400;
    font-size: 0.3rem;
    line-height: 0.54rem;
    margin-top: 0.24rem;
    padding: 0 8%;
  }

  .swip_1 ul {
    list-style: none;
    margin-top: 0.2rem;
    padding: 0 8%;
  }

  .swip_1 ul li {
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 240%;
    display: flex;
    align-items: center;
  }
  .swip_1 ul li img {
    margin-right: 4px;
    width: 0.1rem;
    vertical-align: middle;
  }

  .swip_2 {
    background: url("../../assets/Solution/isPro2_2_2.jpg") no-repeat;
    background-size: cover;
  }

  .swip_2 div:nth-child(1) {
    font-weight: 900;
    font-size: 0.38rem;
    line-height: 0.53rem;
    /* padding-top: 10%; */
    color: #ffffff;
    padding: 10% 8% 0;
  }

  .swip_2 div:nth-child(2) {
    font-weight: 400;
    font-size: 0.3rem;
    line-height: 0.54rem;
    margin-top: 0.24rem;
    padding: 0 8%;
  }
}
</style>